import { Avatar, Button, Card, CardActions, CardContent, Grid, IconButton, InputAdornment, Menu, Pagination, TextField, Typography, alpha, styled } from "@mui/material"
import { grey, stringAvatar } from "../../../../theme/palette"
import DialogComponent from "../../Desktop/sections/dialogs-desktop"
import { Delete, FilterAltOutlined, NavigateNextOutlined, SaveOutlined, SearchOutlined } from "@mui/icons-material";
import { CreationDate, IniciadoEm } from "../../Desktop/sections";
import { EnumTipo, formatarParaNomeStatusViagem } from "../../../../enums/enumPrestacaoDeContas";
import { useEffect, useState } from "react";
import { editarViagem_Mock } from "../../../../mock/prestacaodecontas";
import { ConfirmButtonNovaViagemM, NovaViagemComponentM } from "./NovaViagem";
import { ViagemComponentM } from "./Viagem";
import { Container } from "@material-ui/core";
import prestacaoDeContasRepositorio from "../../../../repositorios/prestacaodecontas";
import { useCustomSnackbar } from "../../../../components/snackbar2/NewSnackBar";
// import { ULoaderVooMessage } from "../../../../components/uloadervoo";
import { IconsSourceSvg } from "../../../../images/Icons";
import ULoaderVooMessage from "../../../../components/uloadervoo/loader";

export const MinhasViagensM = () => {
    const [minhasviagens, setMinhasViagens] = useState([]);
    const [criarNovaviagem, setCriarNovaViagem] = useState(false);
    const [openViagem, setOpenViagem] = useState(false);
    const [viagem, setViagem] = useState(null);
    const [typeDialog, setTypeDialog] = useState(0);
    const [openDeleteViagem, setOpenDeleteViagem] = useState(false)
    const [novaviagem, setNovaviagem] = useState({
        titulo: "",
        id_centrocusto: 0,
        adiantamento: [{ tipo_moeda: EnumTipo.moedas.BRL.id, total: 0, status: EnumTipo.adiantamento.solicitado }],
        totalinicial: 0,
        tem_multimonetario: false,
    });
    const [alterViagem, setAlterViagem] = useState({})
    const [anchorEl, setAnchorEl] = useState(null);
    const [pessoa, setPessoa] = useState({})
    const [loading, setLoading] = useState(false)
    const [loadingMessage, setLoadingMessage] = useState("")
    const [filterViagens, setFilterViagens] = useState(minhasviagens);
    const [selectedDate, setSelectedDate] = useState([null, null]);

    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = filterViagens.slice(startIndex, endIndex);


    const { enqueueSnackbar } = useCustomSnackbar();

    const handlesearchname = (e) => {
        const searchTerm = e.target.value.toLowerCase();

        const filteredViagens = minhasviagens.filter(viagem =>
            viagem.titulo.toLowerCase().includes(searchTerm)
        );

        setPage(1)

        setFilterViagens(filteredViagens);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangePage = (event, value) => {
        setPage(value);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleChangenameViagem = (e) => {
        let title = e.target.value
        setAlterViagem(prevState => ({
            ...prevState,
            titulo: title
        }));
    }

    const getMinhasViagens = async () => {
        setLoading(true);
        setLoadingMessage("Buscando suas viagens...")
        try {
            let model = {
                data_inicial: new Date(new Date().getFullYear(), 0, 1), // 1º de janeiro do ano atual
                data_final: new Date(new Date().getFullYear(), 11, 31), // 31 de dezembro do ano atual
                id_pessoa: 0,
                id_centrocusto: 0,
                minhasviagens: true
            };

            if (selectedDate[0] && selectedDate[1]) {
                model.data_inicial = selectedDate[0];
                model.data_final = selectedDate[1];
            }

            model.data_final.setHours(23, 59, 59, 999);

            const response = await prestacaoDeContasRepositorio.getAllViagensUser(model);

            const sortedViagens = response.viagens.sort((a, b) => new Date(b.datadecriacao) - new Date(a.datadecriacao));

            setMinhasViagens(sortedViagens);

            setPessoa(response.pessoa);
        } catch (error) {
            enqueueSnackbar('Erro ao obter as suas viagens.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const getViagemForID = async () => {
        setLoadingMessage("")
        setLoading(true)
        try {
            const response = await prestacaoDeContasRepositorio.getViagem(viagem.id);
            setViagem(response)
        } catch (error) {
            enqueueSnackbar('Erro ao obter sua viagem.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }


    const confirmCriarNovaViagem = async () => {
        setLoading(true)
        try {
            if (novaviagem.titulo == "" || novaviagem.titulo == null) {
                enqueueSnackbar('Titulo da viagem não pode ser vazio.', { variant: 'warning' });
                return
            }
            if (novaviagem.id_centrocusto === 0) {
                enqueueSnackbar('Centro de custo não pode ser vazio.', { variant: 'warning' });
                return
            }

            if (novaviagem.datafim == null) {
                enqueueSnackbar('Defina uma data final.', { variant: 'warning' });
                return
            }

            if (novaviagem.datainicio == null) {
                enqueueSnackbar('Defina uma data inicial.', { variant: 'warning' });
                return
            }

            setLoadingMessage("Criando sua viagem...")

            novaviagem.adiantamento.forEach(viagem => {
                if (typeof viagem.total === 'string') {
                    let totalString = viagem.total.replace(/\./g, '').replace(',', '.');
                    viagem.total = parseFloat(totalString);
                } else if (typeof viagem.total === 'number') {
                    viagem.total = viagem.total;
                }
            });

            await prestacaoDeContasRepositorio.putNovaViagem(novaviagem);
            enqueueSnackbar('Viagem criada com sucesso.', { variant: 'success' });
            getMinhasViagens()
            setCriarNovaViagem(false)
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao criar viagem.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickViagem = (item) => {
        setViagem(item)
        handleClose()
        setOpenViagem(true)
    }

    const handleClickEditViagem = async () => {
        setLoading(true)
        try {
            setAlterViagem(prevState => ({
                ...prevState, // Copia todos os pares chave-valor existentes
                titulo: "" // Adiciona ou atualiza a chave "titulo" com o novo valor
            }));

            let model = {
                datainicial: alterViagem.datainicial == null ? new Date(viagem.datainicio) : new Date(alterViagem.datainicial),
                datafim: alterViagem.datafim == null ? new Date(viagem.datafim) : new Date(alterViagem.datafim),
                titulo: alterViagem.titulo != "" && alterViagem.titulo != null ? alterViagem.titulo : viagem.titulo
            }

            await prestacaoDeContasRepositorio.putEditViagem(viagem.id, model);
            enqueueSnackbar('Viagem Editada com sucesso.', { variant: 'success' });
            getMinhasViagens()
            getViagemForID()
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao editar viagem.', { variant: 'error' });
        } finally {
            setAlterViagem({})
            setLoading(false)
        }
    }


    const handleClickCriarDespesa = async (despesa) => {
        setLoading(true)
        setLoadingMessage("Adicionando sua despesa...")
        try {
            await prestacaoDeContasRepositorio.postNovaDespesa(despesa);
            enqueueSnackbar('Despesa criada com sucesso.', { variant: 'success' });
            getMinhasViagens()
            getViagemForID()
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao criar despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickEditDespesa = async (despesa) => {
        setLoading(true)
        setLoadingMessage("Editando sua despesa...")
        try {
            await prestacaoDeContasRepositorio.putEditDespesa(despesa)
            enqueueSnackbar('Despesa editada com sucesso.', { variant: 'success' });
            getMinhasViagens()
            getViagemForID()
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao editar despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleDeleteDespesa = async (despesa) => {
        setLoading(true)
        setLoadingMessage("Deletando sua despesa...")
        try {
            await prestacaoDeContasRepositorio.deleteDespesa(despesa.id)
            enqueueSnackbar('Despesa excluida com sucesso.', { variant: 'success' });
            getMinhasViagens()
            getViagemForID()
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao excluida despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleIniciarViagem = async (idviagem) => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasRepositorio.postIniciarViagem(idviagem)
            setViagem(response)
            enqueueSnackbar('Viagem iniciada com sucesso.', { variant: 'success' });
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao iniciar viagem.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleFinalizarViagem = async (idviagem) => {
        setLoading(true)
        setLoadingMessage("Avançando sua viagem...")
        try {
            await prestacaoDeContasRepositorio.postAvancarViagem(idviagem)
            getMinhasViagens()
            getViagemForID()
            enqueueSnackbar('Viagem finalizada com sucesso.', { variant: 'success' });
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao finalizada viagem.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickExcluirViagem = async (idviagem) => {
        setLoading(true)
        setLoadingMessage("Excluindo sua viagem...")
        try {
            await prestacaoDeContasRepositorio.deleteViagem(idviagem)
            setOpenDeleteViagem(false)
            setOpenViagem(false)
            getMinhasViagens()
            enqueueSnackbar('Viagem deletada com sucesso.', { variant: 'success' });
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao deletar viagem.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickCreateHodometro = async (hodometro) => {
        setLoading(true)
        setLoadingMessage("Adicionando seu hodometro...")
        try {
            await prestacaoDeContasRepositorio.postNovaDespesa(hodometro)
            enqueueSnackbar('Adicionado Hodometro.', { variant: 'success' });
            getMinhasViagens()
            getViagemForID()
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao adicionar hodometro.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickEditHodometro = async (hodometro) => {
        setLoading(true)
        setLoadingMessage("Editando seu hodometro...")
        try {
            await prestacaoDeContasRepositorio.putEditDespesa(hodometro)
            enqueueSnackbar('Editado Hodometro manual.', { variant: 'success' });
            getMinhasViagens()
            getViagemForID()
        } catch (e) { // Correção: incluído o parâmetro de erro no catch
            enqueueSnackbar('Ocorreu um erro ao editadar hodometro.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getMinhasViagens();
    }, [])

    useEffect(() => {
        setFilterViagens(minhasviagens)
    }, [minhasviagens])

    return (
        <>
            <ULoaderVooMessage isLoading={loading} message={loadingMessage} />
            <Container maxWidth={"sm"} item xs={12} container style={{ marginBottom: "70px" }}>
                <Grid item xs={12}>
                    <Card style={{ width: 'auto', textAlign: 'center', padding: '16px', borderRadius: '8px' }}>
                        <CardContent>
                            {pessoa?.nome != "" && pessoa?.nome != null && (
                                <Avatar
                                    alt={pessoa?.nome}
                                    src={pessoa?.img}
                                    {...stringAvatar(pessoa?.nome)}
                                    style={{ margin: '0 auto', width: '80px', height: '80px' }}
                                />
                            )}
                            <Typography variant="h6" style={{ marginTop: '8px', fontWeight: 'bold' }}>
                                {pessoa?.nome}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {pessoa?.centrocusto}
                            </Typography>
                        </CardContent>
                        <CardActions style={{ justifyContent: 'center' }}>
                            <Button
                                size="large"
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => setCriarNovaViagem(true)}
                                id="#demo__1">
                                Abrir viagem
                            </Button>
                            <DialogComponent
                                title={"Nova viagem"}
                                open={criarNovaviagem}
                                handleClose={() => setCriarNovaViagem(false)}
                                fullScreen={true}
                                body={<NovaViagemComponentM model={novaviagem} setModel={setNovaviagem} />}
                                action={<ConfirmButtonNovaViagemM setOpen={setCriarNovaViagem} confirmFunction={confirmCriarNovaViagem} />}
                                width={"sm"}
                            />
                        </CardActions>
                    </Card>
                </Grid>
                <Grid container item xs={12} style={{ marginTop: "20px" }}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Pesquisar por título"
                        onChange={(e) => handlesearchname(e)}
                        placeholder="Pesquisar por titulo..."
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchOutlined />
                                </InputAdornment>
                            )
                        }}
                        style={{ borderRadius: "15px", fontStyle: "inherit", fontFamily: "sans-serif" }}
                    />
                </Grid>
                <Grid container item xs={12}>
                    {currentItems?.map((item) => (
                        <>
                            <Grid container item xs={12} style={{ boxShadow: "0px 0px 2.2px 0px rgba(0,0,0,0.5)", marginTop: "14px", padding: "20px", borderRadius: "10px" }}>
                                <Grid item xs={10} style={{ marginTop: "10px" }}>
                                    <Typography style={{ color: grey[800], fontWeight: "600", fontSize: "15px" }}>{item.titulo}</Typography>
                                </Grid>
                                <Grid item xs={2} style={{ textAlign: "right" }}>
                                    <IconButton
                                        id="demo-customized-button"
                                        variant="contained"
                                        disableElevation
                                        onClick={() => handleClickViagem(item)} // Passando o item atual para a função
                                    >
                                        <NavigateNextOutlined />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: "4px", textAlign: "left", justifyContent: "left" }}>
                                    <CreationDate data={item.datadecriacao} />
                                </Grid>
                                <Grid container item xs={12} marginTop={1} style={{ fontFamily: "sans-serif" }}>
                                    <Typography variant="h6" color="textSecondary" sx={{ mb: 1, fontSize: "14px" }}>
                                        {formatarParaNomeStatusViagem(item.status)}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} marginTop={1}>
                                    <IniciadoEm datainicio={item.datainicio} />
                                </Grid>
                            </Grid>
                        </>
                    ))}
                    {currentItems.length == 0 && (
                        <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: "20px" }}>
                            <img src={IconsSourceSvg.pastas}></img>
                            <Typography variant={"body1"} style={{ color: "#818181", marginTop: "10px" }}>Nenhuma viagem encontrada</Typography>
                        </Grid>
                    )}
                    <Grid item xs={12} marginBottom={8} marginTop={2} style={{ display: "flex", justifyContent: "center" }}>
                        {filterViagens.length !== 0 && (
                            <Pagination
                                color="primary"
                                count={Math.ceil(filterViagens.length / itemsPerPage)}
                                page={page}
                                onChange={handleChangePage}
                                siblingCount={0}
                                shape="rounded"
                            />
                        )}
                    </Grid>

                    <DialogComponent
                        title={
                            <>
                                {typeDialog == 0 && viagem != null ? (
                                    <Grid container item xs={12}>
                                        {viagem?.status == 8 ? (
                                            <>
                                                <Typography style={{ fontWeight: "600" }}>{viagem?.titulo}</Typography>
                                            </>
                                        ) : (
                                            <>
                                                <Grid item xs={viagem?.status === 0 ? 9 : 11}>
                                                    <TextField
                                                        size="large"
                                                        variant="standard"
                                                        fullWidth
                                                        defaultValue={viagem?.titulo}
                                                        onChange={(e) => handleChangenameViagem(e)}
                                                        // disabled={(viagem.status == 8 ? true : false)}
                                                        style={{ borderRadius: "15px" }}
                                                    />
                                                </Grid>
                                                <DialogComponent
                                                    title={viagem?.titulo}
                                                    open={openDeleteViagem}
                                                    handleClose={() => setOpenDeleteViagem(false)}
                                                    body={
                                                        <Grid container item xs={12}>
                                                            <Typography>Deseja realmente deletar esse viagem?</Typography>
                                                        </Grid>
                                                    }
                                                    action={
                                                        <Grid>
                                                            <Button size="large" onClick={() => setOpenDeleteViagem(false)}>Cancelar</Button>
                                                            <Button size="large" onClick={() => handleClickExcluirViagem(viagem.id)}>Confimar</Button>
                                                        </Grid>
                                                    }
                                                    width={"sm"}
                                                />
                                                {viagem?.status === 0 ? (
                                                    <Grid item xs={3} style={{ display: "flex", justifyContent: "right" }}>
                                                        <IconButton size="large" variant="contained" color="error" onClick={() => setOpenDeleteViagem(true)}>
                                                            <Delete size="large" />
                                                        </IconButton>
                                                    </Grid>
                                                ) : (
                                                    null
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                ) : (
                                    <Grid container item xs={12}>
                                        <Typography style={{ fontWeight: "600" }}>{viagem?.titulo}</Typography>
                                    </Grid>
                                )}
                            </>
                        }
                        open={openViagem}
                        handleClose={() => { setOpenViagem(false); setViagem(null); }}
                        fullScreen={true}
                        ismap={true}
                        body={
                            <>
                                {Object.keys(alterViagem).length !== 0 ? (
                                    <Grid item xs={12} style={{ textAlign: "right", justifyContent: "right", padding: "20px", marginTop: "-30px" }}>
                                        <Button
                                            size="large"
                                            variant="outlined"
                                            startIcon={<SaveOutlined />}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleClickEditViagem()}
                                        >
                                            Salvar
                                        </Button>
                                    </Grid>
                                ) : (
                                    null
                                )}
                                <ViagemComponentM
                                    viagemData={viagem}
                                    handleCreate={handleClickCriarDespesa}
                                    handleEdit={handleClickEditDespesa}
                                    setTypeGlobal={setTypeDialog}
                                    handleDelete={handleDeleteDespesa}
                                    handleIniciarViagem={handleIniciarViagem}
                                    handleFinalizar={handleFinalizarViagem}
                                    handleClickCreateHodometro={handleClickCreateHodometro}
                                    handleClickEditHodometro={handleClickEditHodometro}
                                />
                            </>
                        }
                        action={<></>}
                        width={"sm"}
                    />
                </Grid>
            </Container>
        </>
    )
}
