import { Settings } from "@mui/icons-material";
import { Avatar, Button, Card, Grow, IconButton, ListItemText } from "@mui/material";
import { stringAvatar } from "../../../theme/palette";

export const MiniPersonCardItem = ({ pessoa, selected, onSelected, index }) => {
    const { nome, centrodecusto_codigo, centrodecusto_nome, img, id } = pessoa;

    return (
        <Grow
            in={true}
            style={{ transformOrigin: '0 0 0' }}
            timeout={(index + 1) * 300} // Atraso na transição para cada card
            key={id} // Use um identificador único do item aqui
        >
            <Card
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: "8px",
                    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)",
                    borderRadius: "10px"
                }}
            >
                <Avatar src={img} {...stringAvatar(nome)} style={{ width: 48, height: 48 }} />

                <ListItemText
                    primary={nome}
                    secondary={centrodecusto_nome + " - " + centrodecusto_codigo}
                    primaryTypographyProps={{
                        noWrap: true,
                        typography: 'subtitle2',
                    }}
                    secondaryTypographyProps={{
                        mt: 0.5,
                        noWrap: true,
                        display: 'flex',
                        component: 'span',
                        alignItems: 'center',
                        typography: 'caption',
                        color: 'text.disabled',
                    }}
                />

                <IconButton onClick={() => onSelected()}>
                    <Settings />
                </IconButton>
            </Card>
        </Grow>
    );
}
