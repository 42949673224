import React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { Avatar, AvatarGroup, Button, CardContent, IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { BlockOutlined, MoreVert, PeopleAltOutlined, ProductionQuantityLimitsOutlined, QrCode2Outlined, SafetyDividerOutlined } from '@mui/icons-material';
import { stringAvatar, stringToColor } from '../../../theme/palette';

export const UserRoleCard = ({ role, qtd, palavras, categorias, onSelect }) => {
    return (
        <Card variant="outlined" style={{ maxWidth: 360, borderRadius: "12px", cursor: "pointer" }} onClick={() => onSelect()}>
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* <Avatar sx={{ bgcolor: '#001e3c' }}>G</Avatar> */}
                    <div>
                        <Typography variant="h6" style={{ fontWeight: "600", color: "#303030" }}>
                            {role}
                        </Typography>
                        <Typography variant="body2" style={{ color: "#515151" }}>
                            {qtd == 0 ? (
                                <>
                                    Nenhum associado
                                </>
                            ) : (
                                <>
                                    {qtd} Associado(s)
                                </>
                            )}
                        </Typography>

                    </div>
                </Box>

                <Divider variant="ul" style={{ marginTop: "10px", marginBottom: "10px" }} />

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, flexDirection: "column" }}>
                    <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: "column", marginBottom: "5px" }}>
                        <Typography variant="h6" style={{ fontSize: "14px", color: "#303030" }}>Categorias:</Typography>
                        <Typography variant="body2">
                            {categorias?.map((item, index) => {
                                const isLast = index === categorias.length - 1;
                                const isSecondLast = index === categorias.length - 2;

                                return (
                                    <>
                                        {item.name}
                                        {isLast ? "." : isSecondLast ? " e " : ", "}
                                    </>
                                )

                            })}
                        </Typography>
                    </Box>
                    {/* <Divider variant="ul" style={{ marginTop: "10px", marginBottom: "10px",  }} />
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: "column" }}>
                        <AvatarGroup max={4}>
                            <Avatar alt="Remy Sharp" {...stringAvatar("Remy Sharp")} sx={{
                                bgcolor: stringToColor("Remy Sharp"),
                                color: '#fff', // Garantir que o texto seja visível
                                mr: 0,
                            }}>RS</Avatar>
                            <Avatar alt="Travis Howard" sx={{
                                bgcolor: stringToColor("ravis Howard"),
                                color: '#fff', // Garantir que o texto seja visível
                                mr: 0,
                            }}>TH</Avatar>
                            <Avatar alt="Cindy Baker" {...stringAvatar("Cindy Baker")} sx={{
                                bgcolor: stringToColor("Cindy Baker"),
                                color: '#fff', // Garantir que o texto seja visível
                                mr: 0,
                            }}>CB</Avatar>
                            <Avatar alt="Agnes Walker">AW</Avatar>
                            <Avatar alt="Trevor Henderson">TH</Avatar>
                        </AvatarGroup>
                    </Box> */}

                    {/* <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: "column" }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <BlockOutlined sx={{ mr: 1 }} style={{ color: "#3B3B3B" }} />
                            <Typography variant="h6" style={{ fontSize: "14px" }}>Palavras proibidas:</Typography>
                        </Box>
                        <Typography variant="body2">
                            {palavras?.length > 0 || palavras !== null ? (
                                <>
                                    <Typography variant="body2" style={{ color: "#515151" }}>
                                        {palavras?.map((item, index) => {
                                            const isLast = index === palavras.length - 1;
                                            const isSecondLast = index === palavras.length - 2;
                                            return (
                                                <>
                                                    {item}
                                                    {isLast ? "." : isSecondLast ? " e " : ", "}
                                                </>
                                            );
                                        })}
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Typography variant="body2" style={{ color: "#515151" }}>Nenhuma palavra nesse grupo</Typography>
                                </>
                            )}
                        </Typography>
                    </Box> */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* <AccessTimeIcon sx={{ mr: 1 }} /> */}
                        {/* <Typography variant="body2">Part-time</Typography> */}
                    </Box>
                </Box>
                {/* <Divider variant="ul" style={{ marginTop: "10px", marginBottom: "10px" }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ProductionQuantityLimitsOutlined sx={{ mr: 1 }} />
                        <Typography variant="body2">Valor Limite</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <QrCode2Outlined sx={{ mr: 1 }} />
                        <Typography variant="body2">QR Code</Typography>
                    </Box>
                </Box> */}
            </CardContent>
        </Card>

    );
};