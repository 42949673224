import { Autocomplete, Avatar, Box, Button, CircularProgress, Container, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Pagination, Select, Stack, Switch, TextField, Tooltip, Typography } from "@mui/material"
import { useEffect, useLayoutEffect, useState } from "react";
import { MiniPersonCardItem } from "../../../../../components/autalii_expanses/minicardperson";
import { prestacaoDeContasConfigRepositorio } from "../../../../../repositorios/prestacaodecontas/config";
import { useCustomSnackbar } from "../../../../../components/snackbar2/NewSnackBar";
import DialogComponent from "../../../Desktop/sections/dialogs-desktop";
import { EmailOutlined, GroupsOutlined, Save, SaveAsOutlined, WhatsApp } from "@mui/icons-material";
import { stringAvatar } from "../../../../../theme/palette";
import pessoaRepositorio from "../../../../../repositorios/pessoa";
import centroCustoRepositorio from "../../../../../repositorios/centroCusto";
import ULoaderVoo from "../../../../../components/uloadervoo";

export const PermissionsListUsers = ({ permissions }) => {
    const [loading, setLoading] = useState(false)
    const [centrodecusto, setcentrodecusto] = useState();
    const [centrosCusto, setCentrosCusto] = useState([]);
    const [pessoas, setPessoas] = useState([]);
    const [pessoa, setPessoa] = useState();
    const [usuarios, setUsuarios] = useState([])
    const [filterUsuarios, setFilterUsuarios] = useState(usuarios)
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(15);
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = filterUsuarios.slice(startIndex, endIndex);
    const [openDialogConfig, setOpenDialogConfig] = useState(false)
    const [selectedPessoa, setSelectedPessoa] = useState(null)
    const [originalPermissoes, setOriginalPermissoes] = useState();
    const [grupos, setGrupos] = useState([])
    const [hasChanges, setHasChanges] = useState(false);
    const [originalPermissionG, setOriginalPermissionG] = useState(false)
    const [pessoaNewGroup, setPessoaNewGroup] = useState({ label: "Nenhum grupo encontrado", value: 0 })

    const { enqueueSnackbar } = useCustomSnackbar();

    useLayoutEffect(() => {
        (async () => {
            const responsePessoas = await pessoaRepositorio.getSearchField();
            const response = await centroCustoRepositorio.getSearchField();
            setCentrosCusto(response);
            setPessoas(responsePessoas)
        })()
    }, [])

    const filterUsers = (pessoa, centrocusto) => {
        let filteredUsers = usuarios;

        if (pessoa !== null && pessoa !== undefined) {
            filteredUsers = filteredUsers.filter(user =>
                user.pessoa.nome.toLowerCase() === pessoa.pessoa.nome.toLowerCase()
            );
        }

        if (centrocusto !== null && centrocusto !== 0) {
            filteredUsers = filteredUsers.filter(user =>
                user.pessoa.centrodecusto_codigo === String(centrocusto)
            );
        }


        setPage(1);
        setFilterUsuarios(filteredUsers);
    };

    const handleChangeCentrodecusto = (value) => {
        setcentrodecusto(value);
        filterUsers(pessoa, value);
    };

    const handleChangePessoa = (event, idusuario) => {
        if (idusuario) {
            setPessoa(idusuario);
            filterUsers(idusuario, centrodecusto)
        } else {
            setPessoa(null);
            filterUsers(idusuario, centrodecusto)
        }
    };

    const handleChangePessoaGrupo = (event) => {
        setSelectedPessoa((prevState) => {
            const updatedPessoa = { ...prevState.pessoa, idgrupo: event.value };

            return {
                ...prevState,
                pessoa: updatedPessoa
            };
        });
        setPessoaNewGroup(event);
    };


    const handleChangePage = (event, value) => {
        setPage(value);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleClickPessoaConfig = (value) => {
        setOriginalPermissoes(value.permissoes)
        setSelectedPessoa(value)
        setOpenDialogConfig(true)
        setHasChanges(false)
    }

    const handleChangePermission = (permission) => {
        setSelectedPessoa((prevState) => {
            const newPermissoes = {
                ...prevState.permissoes,
                [permission]: !prevState.permissoes[permission],
            };
            setHasChanges(!(JSON.stringify(newPermissoes) === JSON.stringify(originalPermissoes)));
            return {
                ...prevState,
                permissoes: newPermissoes,
            };
        });
    };

    const handleChangePermissionViewGroup = (permission) => {
        setSelectedPessoa((prevState) => {
            const newPermissoes = {
                ...prevState.permissoes,
                permissao_editargrupopalavra: !prevState.permissoes[permission],
                permissao_criargrupopalavra: !prevState.permissoes[permission],
                permissao_excluirgrupopalavra: !prevState.permissoes[permission],
                permissao_vergrupopalavra: !prevState.permissoes[permission],
            };
            setHasChanges(!(JSON.stringify(newPermissoes) === JSON.stringify(originalPermissoes)));
            return {
                ...prevState,
                permissoes: newPermissoes,
            };
        });
    };

    const handleSave = async () => {
        setLoading(true)
        try {
            await prestacaoDeContasConfigRepositorio.putEditPermissions(selectedPessoa.pessoa.idusuario, selectedPessoa.permissoes)
            setOpenDialogConfig(false)
            getAllUsers()
            enqueueSnackbar('Configurações aplicadas.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao atualizar configurações.', { variant: 'error' });
        } finally {
            setLoading(false)
            setHasChanges(false);
        }
    };

    const handleSaveGrupo = async () => {
        setLoading(true)
        try {
            await prestacaoDeContasConfigRepositorio.putEditPermissionUser(selectedPessoa.pessoa.idusuario, selectedPessoa.pessoa.idgrupo)
            setOpenDialogConfig(false)
            getAllUsers()
            enqueueSnackbar('Configurações aplicadas.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao atualizar configurações.', { variant: 'error' });
        } finally {
            setLoading(false)
            setHasChanges(false);
        }
    };

    const getAllUsers = async () => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasConfigRepositorio.getAllUsuarios();
            setUsuarios(response)
            setFilterUsuarios(response)
        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar as pessoas.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const getAllGrupos = async () => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasConfigRepositorio.getAllGroupQrCode();
            setGrupos(response)
        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar as pessoas.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    useEffect(() => {
        getAllUsers()
        if (permissions.permissao_vergrupopalavra) {
            getAllGrupos()
        }
    }, [])

    return (
        <Container container maxWidth={'lg'}>
            <ULoaderVoo isLoading={loading} />
            <Grid container item xs={12} style={{ marginBottom: "10px", backgroundColor: "white", borderRadius: "16px" }}>
                <Grid container item xs={12} style={{ boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.5)", borderRadius: "16px", padding: "10px" }}>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="body1" style={{ padding: "5px", color: "#575757", marginBottom: "15px" }}>
                                Lista de usuários com suas permissões e
                                <Tooltip title="Grupos definem as regras específicas para cada tipo de despesa. Veja em Gerenciar Grupos">
                                    <span style={{ color: "#31A552", fontWeight: "600" }}> Grupo </span>
                                </Tooltip>
                                definidos para uso no sistema de prestação de contas.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} alignItems="right" spacing={2}>
                            <Stack
                                spacing={2}
                                alignItems={{ xs: 'flex-end', md: 'center' }}
                                direction={{
                                    xs: 'column',
                                    md: 'row',
                                }}
                                sx={{
                                    p: 0,
                                    marginBottom: "20px"
                                }}
                            >
                                <FormControl style={{ width: "100%" }}>
                                    <InputLabel id="demo-simple-select-label">Centro de custo</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={centrodecusto}
                                        defaultValue={0}
                                        label="Centro de custo"
                                    >
                                        <MenuItem value={0} onClick={() => handleChangeCentrodecusto(0)}>Todos</MenuItem>
                                        {centrosCusto?.map((e) => (
                                            <MenuItem value={e.value} onClick={() => handleChangeCentrodecusto(e.value)}>{e.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            options={usuarios}
                                            getOptionLabel={(option) => option.pessoa.nome}
                                            value={pessoa}
                                            onChange={handleChangePessoa}
                                            renderInput={(params) => <TextField {...params} label="Pessoa" />}
                                            isOptionEqualToValue={(option, value) => option.pessoa.idusuario === value?.pessoa?.idusuario}
                                            filterOptions={(options, { inputValue }) =>
                                                options.filter((option) =>
                                                    // option.pessoa.nome.toLowerCase() === inputValue.toLowerCase()
                                                    option.pessoa.nome.toLowerCase().includes(inputValue.toLowerCase())
                                                )
                                            }
                                        />
                                    </FormControl>
                                </Stack>
                            </Stack>
                            <DialogComponent
                                title={"Opções do usuário"}
                                open={openDialogConfig}
                                handleClose={() => setOpenDialogConfig(false)}
                                fullScreen={true}
                                body={
                                    <Grid container item xs={12} style={{ width: "400px", maxWidth: "auto" }}>
                                        {selectedPessoa != null && selectedPessoa.permissoes != null && (
                                            <>
                                                <Grid container item xs={12} style={{ padding: 10, display: 'flex', alignItems: 'center', boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)", borderRadius: "10px", }}>
                                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', maxWidth: "auto", }}>
                                                        <>
                                                            <Avatar
                                                                src={selectedPessoa.pessoa.img}
                                                                {...stringAvatar(selectedPessoa.pessoa.nome)}
                                                                style={{ width: 48, height: 48 }}
                                                            />
                                                            <div style={{ flexGrow: 1, textAlign: "left", marginLeft: "10px" }}>
                                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                                    {selectedPessoa.pessoa.nome}
                                                                </Typography>
                                                                <Typography variant="body2" color="textSecondary">
                                                                    {selectedPessoa.pessoa.centrodecusto_nome} - {selectedPessoa.pessoa.centrodecusto_codigo}
                                                                </Typography>
                                                            </div>
                                                            {selectedPessoa.pessoa.whats !== "" && selectedPessoa.pessoa.whats !== null && (
                                                                <Tooltip title="Contate-o pelo WhatsApp">
                                                                    <IconButton aria-label="Entrar em contato">
                                                                        <a href={selectedPessoa.pessoa.whats} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                                                                            <WhatsApp />
                                                                        </a>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                            {selectedPessoa.pessoa.email !== "" && selectedPessoa.pessoa.email !== null && (
                                                                <Tooltip title="Contate-o pelo Email">
                                                                    <IconButton aria-label="Entrar em contato">
                                                                        <a href={`mailto:${selectedPessoa.pessoa.email}`} style={{ color: 'inherit' }}>
                                                                            <EmailOutlined />
                                                                        </a>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                        </>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={12} style={{ padding: "0px" }}>
                                                    <List style={{ width: '100%' }}>
                                                        <ListItem style={{ boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.5)', borderRadius: '10px', padding: '10px', marginBottom: '10px' }}>
                                                            <ListItemText primary={"Registrar suas viagens"} style={{ maxWidth: '80%' }} />
                                                            <ListItemSecondaryAction>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        onClick={() => handleChangePermission('permissao_somentepessoal')}
                                                                        control={<Switch checked={selectedPessoa.permissoes.permissao_somentepessoal} />}
                                                                        label="Ativo"
                                                                    />
                                                                </FormGroup>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                        <ListItem style={{ boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.5)', borderRadius: '10px', padding: '10px', marginBottom: '10px' }}>
                                                            <ListItemText primary={"Ser Auditor"} style={{ maxWidth: '80%' }} />
                                                            <ListItemSecondaryAction>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        onClick={() => handleChangePermission('permissao_contabilidade')}
                                                                        control={<Switch checked={selectedPessoa.permissoes.permissao_contabilidade} />}
                                                                        label="Ativo"
                                                                    />
                                                                </FormGroup>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                        <ListItem style={{ boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.5)', borderRadius: '10px', padding: '10px', marginBottom: '10px' }}>
                                                            <ListItemText primary={"Ser controlador"} style={{ maxWidth: '80%' }} />
                                                            <ListItemSecondaryAction>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        onClick={() => handleChangePermission('permissao_controladoria')}
                                                                        control={<Switch checked={selectedPessoa.permissoes.permissao_controladoria} />}
                                                                        label="Ativo"
                                                                    />
                                                                </FormGroup>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                        <ListItem style={{ boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.5)', borderRadius: '10px', padding: '10px', marginBottom: '10px' }}>
                                                            <ListItemText primary={"Ser financeiro"} style={{ maxWidth: '80%' }} />
                                                            <ListItemSecondaryAction>
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        onClick={() => handleChangePermission('permissao_financeiro')}
                                                                        control={<Switch checked={selectedPessoa.permissoes.permissao_financeiro} />}
                                                                        label="Ativo"
                                                                    />
                                                                </FormGroup>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                        <List style={{ width: "100%" }}>
                                                            <ListItem style={{ boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)", borderRadius: "10px", padding: "10px", marginBottom: "0px" }}>
                                                                <ListItemText primary={"Criar grupos"} style={{ maxWidth: "80%" }} />
                                                                <ListItemSecondaryAction>
                                                                    <FormGroup>
                                                                        <FormControlLabel
                                                                            onClick={() => handleChangePermissionViewGroup('permissao_vergrupopalavra')}
                                                                            control={<Switch defaultChecked={selectedPessoa.permissoes?.permissao_vergrupopalavra} />}
                                                                            label="Ativo"
                                                                        />
                                                                    </FormGroup>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        </List>
                                                    </List>
                                                    {hasChanges && (
                                                        <Grid item xs={12} style={{ textAlign: 'right' }}>
                                                            <Button startIcon={<Save />} variant="contained" fullWidth size="large" color="primary" onClick={handleSave}>Atualizar permissões</Button>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                                {permissions.permissao_vergrupopalavra && (
                                                    <>
                                                        <Grid item xs={12} style={{ padding: "10px", marginTop: "0px" }}>
                                                            <Divider width={"100%"} />
                                                        </Grid>
                                                        <Grid container item xs={12} style={{ marginTop: "0px" }}>
                                                            <Grid container item xs={12} >
                                                                <Grid container item xs={12} style={{ display: "flex", alignItems: "center", padding: "10px" }}>
                                                                    <Grid item xs={6} style={{ display: "flex" }}>
                                                                        <GroupsOutlined />
                                                                        <Typography variant="h6" style={{ marginLeft: "5px" }}>
                                                                            Grupo
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6} style={{ display: "flex", alignItems: "center", textAlign: "right", justifyContent: "right" }}>

                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body2" style={{ color: "#555555" }}>
                                                                            Grupos com que o usuário faz parte, as palavras dentro desse grupo serão lidas ao utilizar Qr code e determina assim como categorias.
                                                                        </Typography>
                                                                        <FormControl fullWidth style={{ marginTop: "10px" }}>
                                                                            <InputLabel id="demo-simple-select-label">Associado ao grupo...</InputLabel>
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                value={selectedPessoa.pessoa.idgrupo}
                                                                                // defaultValue={selectedPessoa.pessoa.idgrupo}
                                                                                label="Associado ao grupo..."
                                                                            >
                                                                                {grupos?.map((e) => (
                                                                                    <MenuItem value={e.id_config_group} onClick={() => handleChangePessoaGrupo({ label: e.nameconfig, value: e.id_config_group })}>{e.nameconfig}</MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>

                                                                        <>
                                                                            <Grid item xs={12} style={{ textAlign: 'right' }}>
                                                                                <Button fullWidth size="large" variant="outlined" color="primary" onClick={handleSaveGrupo}>Salvar</Button>
                                                                            </Grid>
                                                                        </>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                }
                                action={<></>}
                                width={"xl"}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {loading ? (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                height="100vh"
                            >
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Box
                                gap={2}
                                display="grid"
                                gridTemplateColumns={{
                                    xs: 'repeat(1, 1fr)', // Uma coluna para telas extra pequenas
                                    sm: 'repeat(2, 1fr)', // Duas colunas para telas pequenas
                                    md: 'repeat(3, 1fr)', // Três colunas para telas médias
                                    lg: 'repeat(4, 1fr)', // Quatro colunas para telas grandes
                                }}
                            >
                                {currentItems?.map((item, index) => (
                                    <MiniPersonCardItem
                                        key={item.pessoa.id}
                                        pessoa={item.pessoa}
                                        index={index}
                                        selected={false}
                                        onSelected={() => handleClickPessoaConfig(item)}
                                    />
                                ))}
                            </Box>
                        )}
                    </Grid>

                    <Grid item xs={12} marginBottom={8} marginTop={2} style={{ display: "flex", justifyContent: "center" }}>
                        <Pagination
                            color="primary"
                            count={Math.ceil(filterUsuarios.length / itemsPerPage)}
                            page={page}
                            onChange={handleChangePage}
                            siblingCount={0}
                            shape="rounded"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}