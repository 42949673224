import React from 'react';
import { Button, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Switch, FormControlLabel, FormGroup } from '@mui/material';
import VercelIcon from '@mui/icons-material/Cloud'; // Placeholder icon for Vercel
import Auth0Icon from '@mui/icons-material/Security'; // Placeholder icon for Auth0
import GoogleCalendarIcon from '@mui/icons-material/Event'; // Placeholder icon for Google Calendar
import StripeIcon from '@mui/icons-material/Payment'; // Placeholder icon for Stripe
import StreetviewIcon from '@mui/icons-material/Streetview';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const services = [
    {
        name: 'GPS',
        description: 'Utilizado GPS para rastrear e registrar a quilometragem percorrida em tempo real, proporcionando um controle preciso e detalhado do seu percurso.',
        icon: <StreetviewIcon />,
    },
    {
        name: 'Qr Code',
        description: 'Utilizado para escanear e ler detalhadamente as informações contidas nas notas fiscais, garantindo uma gestão precisa e eficiente dos seus registros financeiros.',
        icon: <QrCode2Icon />,
    },
    {
        name: 'Auto Despesas',
        description: 'Utilizado para criar Conciliação, garantindo que todas as compras nos sistema estejam na viagem do usuário.',
        icon: <RemoveRedEyeIcon />,
    },
    // {
    //     name: 'Google Calendar',
    //     description: 'Add your personal calendar right into the app',
    //     icon: <GoogleCalendarIcon />,
    // },
    // {
    //     name: 'Stripe',
    //     description: 'See your Stripe balance and manage your products',
    //     icon: <StripeIcon />,
    // },
];

const ServiceList = () => {
    return (
        <List style={{ width: "100%" }}>
            {services.map((service) => (
                <ListItem key={service.name}>
                    <ListItemIcon>
                        {service.icon}
                    </ListItemIcon>
                    <ListItemText primary={service.name} secondary={service.description} style={{ maxWidth: "80%" }} />
                    <ListItemSecondaryAction>
                        <FormGroup>
                            <FormControlLabel control={<Switch defaultChecked />} label="Ativo" />
                        </FormGroup>
                        {/* 
                            <Button variant="contained" color="primary">
                                Install
                            </Button> 
                        */}
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    );
};

export default ServiceList;
