const prod = {
    url: {
        API_URL: window.location.href.includes('192.168.15.100') ? 'http://192.168.15.100:5000/api' : 'https://api.linesturdigital.com/api',
    }
};
const dev = {
    url: {
        API_URL: window.location.href.includes('localhost') ? 'http://localhost:5000/api' : window.location.origin.replace(':3000', ':5000') + '/api'
    }
};

// const config = process.env.NODE_ENV === 'development' ? dev : prod;

const config = {
    url: {
        // API_URL: 'http://26.253.181.157:5000/api',
        API_URL: 'https://api.linesturdigital.com/api',
        // API_URL: 'http://localhost:5000/api',
        // API_URL: window.location.href.includes('192.168.0.21') ? 'http://192.168.0.21:5000/api' : 'http://192.168.0.21:5000/api',
    }
};

export default config;