import { ArrowForwardIosOutlined, ElectricalServicesOutlined, FactCheckOutlined, PrivacyTipOutlined } from "@mui/icons-material"
import { Button, Container, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DialogComponent from "../../Desktop/sections/dialogs-desktop";
import { useState } from "react";
import { PermissionsListUsers } from "./permissionslist";
import { ControlConfigComponent } from "./control";

export const ConfigAllM = ({ permissions }) => {
    const [criarNovaviagem, setCriarNovaViagem] = useState(false);
    const [openControl, setOpenControl] = useState(false);

    return (
        <>
            <DialogComponent
                title={"Usuários"}
                open={criarNovaviagem}
                handleClose={() => setCriarNovaViagem(false)}
                fullScreen={true}
                body={<PermissionsListUsers permissions={permissions} />}
                ismap={true}
                // action={<ConfirmButtonNovaViagemM />}
                width={"sm"}
            />
            <DialogComponent
                title={"Controle"}
                open={openControl}
                handleClose={() => setOpenControl(false)}
                fullScreen={true}
                body={<ControlConfigComponent />}
                ismap={true}
                // action={<ConfirmButtonNovaViagemM />}
                width={"sm"}
            />
            <Container maxWidth="lg">
                <Grid container item xs={12} style={{ backgroundColor: "white", borderRadius: "10px" }}>
                    <Grid container item xs={12} style={{ padding: "0px" }}>
                        <Grid container item xs={12} style={{ borderRadius: "10px", boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)" }}>
                            <List style={{ width: "100%" }}>
                                {permissions?.permissao_editarpermissao && (
                                    <>
                                        <ListItem>
                                            <ListItemIcon>
                                                <PrivacyTipOutlined />
                                            </ListItemIcon>
                                            <ListItemText primary={"Permissões"} style={{ maxWidth: "100%" }} />
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={() => setCriarNovaViagem(true)}>
                                                    <ArrowForwardIosOutlined />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider component="li" />
                                    </>
                                )}
                                {permissions.permissao_vergrupopalavra && (
                                    <ListItem>
                                        <ListItemIcon>
                                            <FactCheckOutlined />
                                        </ListItemIcon>
                                        <ListItemText primary={"Controle"} style={{ maxWidth: "100%" }} />
                                        <ListItemSecondaryAction>
                                            <IconButton onClick={() => setOpenControl(true)}>
                                                <ArrowForwardIosOutlined />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}