import { useCallback, useLayoutEffect, useState } from "react";
import { Button, ButtonGroup, Checkbox, Container, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputAdornment, InputLabel, MenuItem, Select, Slider, TextField, Typography } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search';
import { grey, themepalette } from "../../../../../theme/palette"
import { centrosdecustos_Mock } from "../../../../../mock/centrodecusto";
import CustomDateRangePicker from "../../sections/custom-date-range-picker";
import DateDifference from "../../sections/custom-date-difference";
import UtaliiDatePickerInput from "../../sections/custom-date-picker-start_and_end";
import dayjs from "dayjs";
import { Add, Delete, EditOutlined } from "@mui/icons-material";
import centroCustoRepositorio from "../../../../../repositorios/centroCusto";
import { EnumTipo, formatarMoeda, getCurrencySymbol } from "../../../../../enums/enumPrestacaoDeContas";
import Flag from "react-world-flags";

export const NovaViagemComponent = ({ model, setModel }) => {
    const [selectedDate, setSelectedDate] = useState([null, null]);
    const [datainicio, setDataInicio] = useState(null);
    const [datafim, setDataFim] = useState(null);
    const [centrodecusto, setcentrodecusto] = useState(0);
    const [centrosCusto, setCentrosCusto] = useState([]);
    const [currentTab, setCurrentTab] = useState("Nacional")
    const [gerenciamento, setGerenciamento] = useState("com")
    const [custo, setCusto] = useState(0);
    const [moedaValorCampos, setMoedaValorCampos] = useState([
        { tipo_moeda: EnumTipo.moedas.USD.id, total: 0, status: EnumTipo.adiantamento.solicitado }
    ]);
    const [totalInicial, setTotalIncial] = useState(0)
    const [checkedAd, setCheckedAd] = useState(true)

    const handleMoedaChange = (index, event) => {
        const newMoedaValorCampos = [...moedaValorCampos];
        newMoedaValorCampos[index].tipo_moeda = event.target.value;
        setMoedaValorCampos(newMoedaValorCampos);
        setModel((prevModel) => ({
            ...prevModel,
            tem_multimonetario: true,
            adiantamento: newMoedaValorCampos // Atualiza o modelo com os valores atuais
        }));
    };

    const handleValorChange = (index, event) => {
        const newMoedaValorCampos = [...moedaValorCampos];
        const valorString = event.target.value;

        // Converte o valor para float, removendo possíveis separadores de milhar
        // const valorFloat = parseFloat(valorString.replace(/[^0-9.-]+/g, ""));

        // Armazena o valor como float após a formatação
        newMoedaValorCampos[index].total = formatarMoeda(valorString);
        setMoedaValorCampos(newMoedaValorCampos);
        setModel((prevModel) => ({
            ...prevModel,
            tem_multimonetario: true,
            adiantamento: newMoedaValorCampos // Atualiza o modelo com os valores atuais
        }));
    };


    const handleStatusChange = (index, event) => {
        const newMoedaValorCampos = [...moedaValorCampos];
        newMoedaValorCampos[index].status = event.target.checked ? EnumTipo.adiantamento.solicitado : EnumTipo.adiantamento.naosolicitado;
        setMoedaValorCampos(newMoedaValorCampos);
        setModel((prevModel) => ({
            ...prevModel,
            adiantamento: newMoedaValorCampos // Atualiza o modelo com os valores atuais
        }));
    };

    const adicionarMaisUmCampo = () => {
        setMoedaValorCampos([...moedaValorCampos, { tipo_moeda: EnumTipo.moedas.USD.id, total: '0.00', status: EnumTipo.adiantamento.solicitado }]);
    };

    const removerCampo = (index) => {
        const newMoedaValorCampos = moedaValorCampos.filter((_, i) => i !== index);
        setMoedaValorCampos(newMoedaValorCampos);
        setModel((prevModel) => ({
            ...prevModel,
            adiantamento: newMoedaValorCampos // Atualiza o modelo com os valores atuais
        }));
    };

    useLayoutEffect(() => {
        (async () => {
            const response = await centroCustoRepositorio.getSearchField();
            // await getData();
            setCentrosCusto(response);
        })()
    }, [])

    const handleInputChange = (event) => {
        const value = event.target.value;
        if (event.target.value >= 0 && event.target.value <= 1500) {
            setCusto((prevModel) => ({
                ...prevModel,
                total: value
            }))
            setTotalIncial(value)
            setModel(prevModel => ({
                ...prevModel,
                adiantamento: [{ tipo_moeda: custo.tipo_moeda, total: value, status: custo.status }],
                totalinicial: value
            }));
        } else if (value > 1500) {
            setCusto(1500);
        }
    };

    const handleBlur = () => {
        if (custo < 0) {
            setCusto(0);
        } else if (custo >= 1500) {
            setCusto(1500);
        }
    };


    const handleChangeTituloDaViagem = (e) => {
        let titulo = e.target.value
        setModel(prevModel => ({
            ...prevModel,
            titulo: titulo
        }));
    }

    const handleChangeCentrodecusto = (event) => {
        setcentrodecusto(event.value);
        setModel(prevModel => ({
            ...prevModel,
            id_centrocusto: event.value
        }));
    };

    const handleChangeCusto = (e) => {
        setCusto((prevModel) => ({
            ...prevModel,
            total: e.target.value
        }))
        setTotalIncial(e.target.value)
        setModel(prevModel => ({
            ...prevModel,
            adiantamento: [{ tipo_moeda: EnumTipo.moedas.BRL.id, total: e.target.value, status: EnumTipo.adiantamento.solicitado }],
            totalinicial: e.target.value
        }));
    }

    const onChangeAdiantamento = (newValue) => {
        // setCheckedAd(newValue ? EnumTipo.adiantamento.solicitado : EnumTipo.adiantamento.naosolicitado)
        setModel(prevModel => ({
            ...prevModel,
            adiantamento: [{ tipo_moeda: EnumTipo.moedas.BRL.id, total: totalInicial, status: newValue ? EnumTipo.adiantamento.solicitado : EnumTipo.adiantamento.naosolicitado }],
            totalinicial: custo.total
        }));
    };

    const handleDateChange = (newValue) => {
        setSelectedDate(newValue);
        if (newValue[0] && newValue[1]) {
            setDataInicio(newValue[0].toDate());
            setDataFim(newValue[1].toDate());

            setModel(prevModel => ({
                ...prevModel,
                datainicio: newValue[0].toDate(),
                datafim: newValue[1].toDate()
            }));
        }
    };

    const handleChangeTab = (value) => {
        setCurrentTab(value)
        if (value === "Internacional") {
            setModel((prevModel) => ({
                ...prevModel,
                tem_multimonetario: true,
                tipo_moeda: 0,
                totalinicial: 0
            }));
        } else {
            setModel((prevModel) => ({
                ...prevModel,
                adiantamento: [{ tipo_moeda: EnumTipo.moedas.BRL.id, total: totalInicial, status: EnumTipo.adiantamento.naosolicitado }],
                tem_multimonetario: false,
                tipo_moeda: 0,
                totalinicial: 0
            }));
        }
    }

    return (
        <>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} style={{ marginTop: "2px" }}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        onChange={(e) => handleChangeTituloDaViagem(e)}
                        label="Título da viagem"
                        placeholder="Viagem para..."
                        style={{ borderRadius: "15px" }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Centro de Custo</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={centrodecusto}
                            defaultValue={0}
                            label="Centro de custo"

                        >
                            {centrosCusto?.map((e) => (
                                <MenuItem value={e.value} onClick={() => handleChangeCentrodecusto({ label: e.label, value: e.value })}>{e.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "-10px", display: "flex" }}>
                    <UtaliiDatePickerInput
                        value={selectedDate}
                        onChange={handleDateChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <DateDifference startDate={datainicio} endDate={datafim} />
                </Grid>

                <Grid item xs={12} style={{ marginBottom: "15px", marginTop: "-5px", display: "flex", justifyContent: "center" }}>
                    <ButtonGroup
                        variant="outlined"
                        color="inherit"
                        style={{ justifyContent: "center", borderColor: "#D6D6D6", borderRadius: "10px" }}
                    >
                        <Button
                            size="large"
                            onClick={() => handleChangeTab('Nacional')}
                            style={{
                                textTransform: "none", // Mantém a capitalização original do texto
                                fontWeight: currentTab === 'Nacional' ? 600 : 400, // Destaca o botão ativo
                                borderColor: currentTab === 'Nacional' ? "#1C252E" : "#D6D6D6",
                                backgroundColor: currentTab === 'Nacional' ? "#1C252E" : "#FFFFFF",
                                color: currentTab === 'Nacional' ? "#FFFFFF" : "#1C252E",
                                borderRadius: "8px 0 0 8px" // Aplica borderRadius apenas nos extremos
                            }}
                        >
                            Nacional
                        </Button>
                        <Button
                            size="large"
                            onClick={() => handleChangeTab('Internacional')}
                            style={{
                                textTransform: "none", // Mantém a capitalização original do texto
                                fontWeight: currentTab === 'Internacional' ? 600 : 400, // Destaca o botão ativo
                                borderColor: currentTab === 'Internacional' ? "#1C252E" : "#D6D6D6",
                                backgroundColor: currentTab === 'Internacional' ? "#1C252E" : "#FFFFFF",
                                color: currentTab === 'Internacional' ? "#FFFFFF" : "#1C252E",
                                borderRadius: "0 8px 8px 0" // Aplica borderRadius apenas nos extremos
                            }}
                        >
                            Internacional
                        </Button>
                    </ButtonGroup>
                </Grid>


                {currentTab === 'Nacional' && (
                    <Grid container item xs={12} style={{ marginTop: "-10px" }}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                Total inicial
                            </Typography>
                            <Typography variant="h3" style={{ fontWeight: 'bold' }}>
                                R$
                                <TextField
                                    value={custo.total}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    inputProps={{
                                        step: 0.1,
                                        min: 0,
                                        max: 1500,
                                        type: 'number',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EditOutlined />
                                            </InputAdornment>
                                        ),
                                        style: { fontSize: '28px', fontWeight: 'bold' }
                                    }}
                                    variant="standard"
                                    style={{ width: '100px', fontSize: '28px', fontWeight: 'inherit' }}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{ display: "flex", justifyContent: "right", textAlign: "right", flexDirection: "column", alignItems: "flex-end" }}>
                            <FormGroup>
                                <FormControlLabel value={checkedAd} control={<Checkbox onClick={(e) => onChangeAdiantamento(e.target.checked)} />} label="Pedir adiantamento" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} style={{ padding: "0px 20px 0px 20px" }}>
                            <Slider
                                marks
                                value={custo.total}
                                step={100}
                                onChange={(e) => handleChangeCusto(e)}
                                max={1500}
                                min={0}
                                size="medium"
                            />
                        </Grid>
                    </Grid>
                )}

                {currentTab === 'Internacional' && (
                    <Grid container item xs={12}>
                        {gerenciamento === 'com' && (
                            <>
                                <FormControl fullWidth>
                                    <FormLabel id="demo-controlled-radio-buttons-group" style={{ marginTop: "5px", marginBottom: "0px" }}>Operação:</FormLabel>
                                    {moedaValorCampos.map((campo, index) => (
                                        <Grid container item xs={12} key={index} style={{ marginBottom: '15px' }}>
                                            <Grid item xs={12} container spacing={2} style={{ marginBottom: '0px', marginTop: "0px" }}>
                                                <Grid item xs={12}>
                                                    <FormControl fullWidth variant="outlined" style={{ minWidth: 120 }}>
                                                        <InputLabel id={`select-moeda-label-${index}`}>Moeda</InputLabel>
                                                        <Select
                                                            labelId={`select-moeda-label-${index}`}
                                                            id={`select-moeda-${index}`}
                                                            value={campo.tipo_moeda}
                                                            onChange={(event) => handleMoedaChange(index, event)}
                                                            label="Moeda"
                                                        >
                                                            {Object.keys(EnumTipo.moedas).map((key) => {
                                                                const item = EnumTipo.moedas[key];
                                                                return (
                                                                    <MenuItem key={item.id} value={item.id}>
                                                                        <Flag code={item.flagCode} width="20" style={{ marginRight: '10px' }} /> {item.nome}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Valor"
                                                        value={campo.total ? formatarMoeda(String(campo.total)) : ''}
                                                        onChange={(event) => handleValorChange(index, event)}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    {getCurrencySymbol(campo.tipo_moeda)}
                                                                </InputAdornment>
                                                            ),
                                                            inputProps: {
                                                                inputMode: 'numeric',
                                                                style: { fontSize: '18px', fontWeight: 'bold' }
                                                            }
                                                        }}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                {/* <Grid item xs={12} style={{ display: "flex", justifyContent: "right", textAlign: "right", flexDirection: "column", alignItems: "flex-end", marginTop: "20px" }}>
                                                    <FormGroup>
                                                        <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                            label="Solicitar adiantamento"
                                                            labelPlacement="start"
                                                            control={
                                                                <Checkbox
                                                                    size="large"
                                                                    checked={campo.status === EnumTipo.adiantamento.solicitado}
                                                                    onChange={(event) => handleStatusChange(index, event)}
                                                                />
                                                            } />
                                                    </FormGroup>
                                                </Grid> */}
                                            </Grid>
                                            {index !== 0 && (
                                                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Button startIcon={<Delete />} color="error" size="large" fullWidth onClick={() => removerCampo(index)} variant="outlined">Remover</Button>
                                                </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                <Divider variant="ul" style={{ width: "100%", marginTop: "15px", marginBottom: "15px" }}></Divider>
                                            </Grid>
                                        </Grid>
                                    ))}
                                    <Grid item xs={12} style={{ textAlign: "right" }}>
                                        <Button startIcon={<Add />} size="large" onClick={adicionarMaisUmCampo} variant="contained" style={{ color: "#FFFFFF", backgroundColor: grey[800] }}>Nova moeda</Button>
                                    </Grid>
                                </FormControl>
                            </>
                        )}
                    </Grid>
                )}

            </Grid>
        </>
    )
}

export const ConfirmButtonNovaViagem = ({ confirmFunction }) => {
    return (
        <>
            <Button variant="contained" color="primary" fullWidth autoFocus onClick={() => confirmFunction()}>
                Finalizar
            </Button>
        </>
    )
}