import React, { useEffect, useState } from 'react';
import { Button, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Switch, FormControlLabel, FormGroup, Grid, Typography, IconButton, Box, TextField, Autocomplete, Chip, Card, CardContent, Avatar, InputAdornment, Divider, ButtonGroup, Pagination } from '@mui/material';
import { Add, Delete, QrCode2Outlined, SearchOutlined } from '@mui/icons-material';
import { UserRoleCard } from '../../../../../../components/autalii_expanses/minicardgroupperson';
import DialogComponent from '../../../sections/dialogs-desktop';
import { useCustomSnackbar } from '../../../../../../components/snackbar2/NewSnackBar';
import { EnumTipo, formatarMoeda, getCurrencySymbol } from '../../../../../../enums/enumPrestacaoDeContas';
import { prestacaoDeContasConfigRepositorio } from '../../../../../../repositorios/prestacaodecontas/config';
import prestacaoDeContasRepositorio from '../../../../../../repositorios/prestacaodecontas';

const PalavrasConfigQrCode = ({ grupos, handleClickCreateGroup, handleClickEditGroup, handleClickExcluirGroup }) => {
    const [addNewGroup, setAddNewGroup] = useState(false);
    const [editGroup, setEditGroup] = useState(false)
    const [inputValue, setInputValue] = useState('');
    const [tags, setTags] = useState([]);
    const [titulo, setTitulo] = useState(null);
    const [id, setId] = useState(0);
    const [categorias, setCategorias] = useState([])
    const [openDeleteGrupo, setOpenDeleteGrupo] = useState(false)
    const [types, setTypes] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const { enqueueSnackbar } = useCustomSnackbar();

    const filteredGrupos = grupos.filter((item) =>
        item.nameconfig.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSubtipoChange = (typeIndex, subtipoIndex, field, value) => {
        const newTypes = [...types];
        newTypes[typeIndex].produtos[subtipoIndex].limite = value;
        setTypes(newTypes);
    };

    const handleSubtipoLimiteChange = (typeIndex, subtipoIndex, field, value) => {
        const newTypes = [...categorias];
        newTypes[typeIndex].subtipo[subtipoIndex].limite = value;
        setCategorias(newTypes);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue.trim() !== '') {
            setTags((prevTags) => [...prevTags, inputValue.trim()]);
            setInputValue('');
            event.preventDefault(); // Previne a submissão do formulário
        }
    };

    const handleDeleteTag = (tagToDelete) => () => {
        setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
    };

    const handleClickCreate = () => {
        if (titulo === "") {
            enqueueSnackbar('Adicione um título ao grupo.', { variant: 'warning' });
            return
        }

        let listsubtipos = []

        types.forEach(value => {
            value.produtos.forEach(produto => {
                if (typeof produto.limite === 'string') {
                    let totalString = formatarMoeda(produto.limite).replace(/\./g, '').replace(',', '.');
                    listsubtipos.push({ iddespesa_subtipo: produto.iddespesa_subtipo, limite: parseFloat(totalString) })
                } else if (typeof produto.limite === 'number') {
                    listsubtipos.push({ iddespesa_subtipo: produto.iddespesa_subtipo, limite: produto.limite })
                }
            })
        })

        handleClickCreateGroup(
            {
                wordsnotpermited: tags,
                nameconfig: titulo,
                listSubtipos: listsubtipos
            }
        )
        setAddNewGroup(false)
    }

    const handleClickEdit = () => {
        if (titulo === "") {
            enqueueSnackbar('Adicione um título ao grupo.', { variant: 'warning' });
            return
        }

        let listsubtipos = []

        categorias.forEach(value => {
            value.subtipo.forEach(produto => {
                if (typeof produto.limite === 'string') {
                    let totalString = formatarMoeda(produto.limite).replace(/\./g, '').replace(',', '.');
                    listsubtipos.push({ id_limite_grupo: produto.id_limite_grupo, limite: parseFloat(totalString) })
                } else if (typeof produto.limite === 'number') {
                    listsubtipos.push({ id_limite_grupo: produto.id_limite_grupo, limite: produto.limite })
                }
            })
        })

        handleClickEditGroup(
            id,
            {
                wordsnotpermited: tags,
                nameconfig: titulo,
                listSubtipos: listsubtipos
            }
        )

        setEditGroup(false)
    }

    const handleClickConfirmExcluir = () => {
        handleClickExcluirGroup(id)
        setEditGroup(false)
    }

    const handleSelectEditGroup = (item) => {
        setCategorias(item.tipo)
        setTitulo(item.nameconfig)
        setTags(item.wordsnotpermited)
        setId(item.id_config_group)
        setEditGroup(true)
    }

    const BuscarTodosGrupoConfiguracao = async () => {
        try {
            let response = await prestacaoDeContasRepositorio.getAllTiposDeDespesas()

            response.forEach(value => {
                value.produtos.forEach(produto => {
                    produto.limite = -1
                })
            })

            setTypes(response)
        } catch {
        } finally {
        }
    }

    useEffect(() => {
        BuscarTodosGrupoConfiguracao()
    }, [])

    return (
        <Grid container item xs={12}>
            <Grid item xs={12}>
                <DialogComponent
                    title={"Novo grupo"}
                    open={addNewGroup}
                    handleClose={() => setAddNewGroup(false)}
                    body={
                        <Grid container spacing={2} style={{ width: "400px", maxWidth: "auto" }}>
                            <Grid item xs={12} style={{ marginTop: "3px" }}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Nome do Grupo"
                                    defaultValue={titulo}
                                    onChange={(e) => setTitulo(e.target.value)}
                                    color="primary"
                                    style={{ borderRadius: "15px", marginTop: "2px" }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider variant="ul" style={{ marginTop: "2px" }} />
                            </Grid>

                            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                <Typography variant="body2" style={{ color: "#757575", display: "flex", textAlign: "left" }}>
                                    Quando o limite do subtipo for definido como 0, ele será interpretado como ilimitado.
                                </Typography>
                            </Grid>

                            {types?.map((type, typeIndex) => (
                                <>
                                    {type.name === "Hodometro" || type.name === "Outros" || type.name === "Pendente" ? (
                                        null
                                    ) : (
                                        <Grid container item xs={12} key={typeIndex} spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="h6">{type.name}</Typography>
                                            </Grid>
                                            {type.produtos?.map((subtipo, subtipoIndex) => (
                                                <Grid container item xs={12} key={subtipoIndex} spacing={2}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            value={subtipo.name}
                                                            disabled={true}
                                                            onChange={(e) => handleSubtipoChange(typeIndex, subtipoIndex, 'name', e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            label="Valor"
                                                            value={subtipo.limite ? formatarMoeda(String(typeof subtipo.limite === 'string' || subtipo.limite === -1 ? subtipo.limite : subtipo.limite * 100)) : ''}
                                                            onChange={(event) => handleSubtipoChange(typeIndex, subtipoIndex, 'limite', event.target.value)}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        {getCurrencySymbol(EnumTipo.moedas.BRL.id)}
                                                                    </InputAdornment>
                                                                ),
                                                                inputProps: {
                                                                    inputMode: 'numeric',
                                                                    style: { fontSize: '18px', fontWeight: 'bold' }
                                                                }
                                                            }}
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    )}
                                </>
                            ))}

                            <Grid container item xs={12} style={{ display: "flex" }}>
                                <QrCode2Outlined />
                                <Typography variant="h6" style={{ marginLeft: "10px" }}>
                                    Qr code
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="body2" style={{ color: "#595959" }}>
                                    As palavras adicionadas servirão como filtros para o leitor de QR Code.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} style={{ marginBottom: "0px" }}>
                                <TextField
                                    label="Palavras"
                                    placeholder="Ex: (Barra de chocolate, Cerveja, Refrigerante...)"
                                    helperText="Escreva e pressione enter"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ mt: 0, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    {tags?.map((tag, index) => (
                                        <Chip
                                            key={index}
                                            label={tag}
                                            onDelete={handleDeleteTag(tag)}
                                            variant="outlined"
                                            style={{ backgroundColor: "#FFFFFF" }}
                                        />
                                    ))}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Button onClick={handleClickCreate} size="large" fullWidth variant="contained" color="primary">
                                    Criar grupo
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    action={
                        <>
                        </>
                    }
                    width={"xl"}
                />
                <DialogComponent
                    title={
                        <Grid container item xs={12}>
                            <Grid item xs={10} style={{}}>
                                <Typography style={{ fontWeight: "600" }}>Editar grupo</Typography>
                            </Grid>
                            <Grid item xs={2} style={{ display: "grid" }}>
                                <DialogComponent
                                    title={"Deletar grupo"}
                                    open={openDeleteGrupo}
                                    handleClose={() => setOpenDeleteGrupo(false)}
                                    body={
                                        <Grid container item xs={12}>
                                            <Typography>Deseja realmente deletar esse viagem?</Typography>
                                        </Grid>
                                    }
                                    action={
                                        <Grid>
                                            <Button onClick={() => setOpenDeleteGrupo(false)}>Cancelar</Button>
                                            <Button onClick={() => handleClickConfirmExcluir()}>Confimar</Button>
                                        </Grid>
                                    }
                                    width={"sm"}
                                />
                                <IconButton size="large" variant="contained" color="error" onClick={() => setOpenDeleteGrupo(true)}>
                                    <Delete size="large" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    }
                    open={editGroup}
                    handleClose={() => { setEditGroup(false); setTitulo(null) }}
                    body={
                        <Grid container spacing={2} style={{ width: "400px", maxWidth: "auto" }}>
                            <Grid item xs={12} style={{ marginTop: "3px" }}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Nome do Grupo"
                                    value={titulo}
                                    defaultValue={""}
                                    onChange={(e) => setTitulo(e.target.value)}
                                    color="primary"
                                    style={{ borderRadius: "15px", marginTop: "2px" }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider variant="ul" style={{ marginTop: "2px" }} />
                            </Grid>
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                <Typography variant="body2" style={{ color: "#757575", display: "flex", textAlign: "left" }}>
                                    Quando o limite do subtipo for definido como 0, ele será interpretado como ilimitado.
                                </Typography>
                            </Grid>

                            {categorias?.map((type, typeIndex) => (
                                <>
                                    {type.name === "Hodometro" || type.name === "Outros" ? (
                                        null
                                    ) : (
                                        <Grid container item xs={12} key={typeIndex} spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="h6">{type.name}</Typography>
                                            </Grid>
                                            {type.subtipo?.map((subtipo, subtipoIndex) => (
                                                <Grid container item xs={12} key={subtipoIndex} spacing={2}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            value={subtipo.name}
                                                            disabled={true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            fullWidth
                                                            label="Valor"
                                                            value={subtipo.limite ? formatarMoeda(String(typeof subtipo.limite === 'string' || subtipo.limite === -1 ? subtipo.limite : subtipo.limite * 100)) : ''}
                                                            onChange={(event) => handleSubtipoLimiteChange(typeIndex, subtipoIndex, 'limite', event.target.value)}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        {getCurrencySymbol(EnumTipo.moedas.BRL.id)}
                                                                    </InputAdornment>
                                                                ),
                                                                inputProps: {
                                                                    inputMode: 'numeric',
                                                                    style: { fontSize: '18px', fontWeight: 'bold' }
                                                                }
                                                            }}
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    )}
                                </>
                            ))}

                            <Grid container item xs={12} style={{ display: "flex" }}>
                                <QrCode2Outlined />
                                <Typography variant="h6" style={{ marginLeft: "10px" }}>
                                    Qr code
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="body2" style={{ color: "#595959", padding: "4px" }}>
                                    As palavras adicionadas servirão como filtros para o leitor de QR Code.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} style={{ marginBottom: "0px" }}>
                                <TextField
                                    label="Palavras"
                                    placeholder="Ex: (Barra de chocolate, Cerveja, Refrigerante...)"
                                    helperText="Escreva e pressione enter"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ mt: 0, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    {tags?.map((tag, index) => (
                                        <Chip
                                            key={index}
                                            label={tag}
                                            onDelete={handleDeleteTag(tag)}
                                            variant="outlined"
                                            style={{ backgroundColor: "#FFFFFF" }}
                                        />
                                    ))}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Button onClick={handleClickEdit} size="large" fullWidth variant="contained" color="primary">
                                    Editar grupo
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    action={<>
                    </>}
                    width={"xl"}
                />

                <Grid container item xs={12} spacing={0} style={{ marginBottom: "20px" }}>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Pesquisar por título"
                            placeholder="Pesquisar por título..."
                            size="large"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)} // Atualiza o termo de pesquisa
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchOutlined />
                                    </InputAdornment>
                                )
                            }}
                            style={{ borderRadius: "15px" }}
                        />
                    </Grid>
                    <Grid item xs={6} style={{ display: "flex", justifyContent: "right", alignItems: "flex-end", padding: "3px" }}>
                        <Button style={{ height: "100%" }} onClick={() => setAddNewGroup(true)} size="large" variant="contained" color="primary" startIcon={<Add />}>Novo Grupo</Button>
                    </Grid>
                </Grid>

                <Box
                    gap={1}
                    display="grid"
                    gridTemplateColumns={{
                        xs: 'repeat(1, 1fr)',
                        sm: 'repeat(2, 1fr)',
                        md: 'repeat(3, 1fr)',
                    }}
                >
                    {filteredGrupos.length !== 0 ? (
                        filteredGrupos.map((item) => (
                            <UserRoleCard
                                key={item.nameconfig} // Certifique-se de que cada item tenha uma key única
                                qtd={item.qtd_usuarios}
                                role={item.nameconfig}
                                palavras={item.wordsnotpermited}
                                categorias={item.tipo}
                                onSelect={() => {
                                    handleSelectEditGroup(item);
                                    setTitulo(item.nameconfig);
                                }}
                            />
                        ))
                    ) : (
                        <p>Nenhum grupo encontrado</p>
                    )}
                </Box>
                <Grid item xs={12} style={{ padding: "20px", display: "flex", justifyContent: "center" }}>
                    <Pagination
                        color="primary"
                        siblingCount={0}
                        shape="rounded"
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PalavrasConfigQrCode;
