import { Box, Button, Chip, Grid, Typography } from "@mui/material"
import { CreationDate, IniciadoEm } from "../../../prestacaodecontas/Desktop/sections"
import { DeskCardPersonExpanses } from "../../../../components/card_person_expanses"
import { formatarParaNomeStatusAdiantamento } from "../../../../enums/enumPrestacaoDeContas"
import { OrderTimeline } from "../Components/ordertimeline"
import { getHistory_Mock, historico_Mock } from "../../../../mock/historico"
import { useEffect, useState } from "react"
import { useCustomSnackbar } from "../../../../components/snackbar2/NewSnackBar"
import financeiroRepositorio from "../../../../repositorios/financeiro"
import ULoaderVoo from "../../../../components/uloadervoo"
import prestacaoDeContasRepositorio from "../../../../repositorios/prestacaodecontas"

export const HistoricoDePagamentosComponentMobile = ({ viagem }) => {
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(false)
    const [despesas, setDespesas] = useState([])

    const { enqueueSnackbar } = useCustomSnackbar();

    const formatDate = (date) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(date).toLocaleDateString('pt-BR', options);
    };

    const getHistory = async () => {
        setLoading(true)
        try {
            const response = await financeiroRepositorio.getHistory(viagem.id)
            setHistory(response)
        } catch {
            enqueueSnackbar('Erro ao obter historico.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const getDespesas = async () => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasRepositorio.getDespesas(viagem.id);
            setDespesas(response.despesas)
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao buscar despesas.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getHistory()
        getDespesas()
    }, [])

    return (
        <>
            <ULoaderVoo isLoading={loading} />
            <Grid container spacing={2} sx={{ p: 0 }}>
                {/* Informações da Viagem */}
                <Grid item xs={12} container spacing={2}>

                    <Grid item xs={12} style={{ textAlign: "center", justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <DeskCardPersonExpanses viagem={viagem} despesas={despesas} fullScreen={true} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ p: 2, border: '1px solid #e0e0e0', borderRadius: '8px' }}>
                            <Typography variant="h6" gutterBottom>
                                Pagamento / Transação - Viagem Atual
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="body2" color="textSecondary">Status</Typography>
                                </Grid>
                                <Grid item xs={8} style={{ textAlign: 'right' }}>
                                    <Typography>
                                        {formatarParaNomeStatusAdiantamento(viagem.adiantamento?.status, viagem.totalinicial)}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Data de Inicio</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <Typography variant="body2">{formatDate(viagem.datainicio)}</Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography variant="body2" color="textSecondary">Data do Final</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <Typography variant="body2">{formatDate(viagem.datafim)}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {history.length == 0 ? (
                        <>
                            Nenhum historico encontrado
                        </>
                    ) : (
                        <OrderTimeline events={history} fullScreen={true}/>
                    )}
                </Grid>
            </Grid>
        </>
    )
}