import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card, CardContent, Typography, Chip, Grid, Divider, Box, Button, ButtonGroup } from '@mui/material';
import { CreationDate } from '../../../Desktop/sections';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DialogComponent from '../../../Desktop/sections/dialogs-desktop';
import { CurrencyExchangeOutlined, QrCode2Outlined } from '@mui/icons-material';
import { ExtraAddComponentM } from '../Extras';
import prestacaoDeContasRepositorio from '../../../../../repositorios/prestacaodecontas';
import { useCustomSnackbar } from '../../../../../components/snackbar2/NewSnackBar';
import ULoaderVoo from '../../../../../components/uloadervoo';
import { ViewHodometerExpansesComponent } from '../../../../../components/autalii_expanses/viewhodometer_expanses';
import { DeskCardPersonExpanses } from '../../../../../components/card_person_expanses';
import { ViewQrCodeProducts } from '../../../../../components/autalii_expanses/viewqrcode';
import { formatarValorComMoeda } from '../../../../../enums/enumPrestacaoDeContas';
import { DialogViewImage } from '../../../../../components/autalii_expanses/dialogs/dialog_MobileImage';

export const DetalhesViagemComponentM = ({ viagem }) => {
    const [despesa, setDespesa] = useState([])
    const [openInfoHodometro, setOpenInfoHodometro] = useState(false);
    const [openComprovante, setOpenComprovante] = useState(false)
    const [currentTab, setCurrentTab] = useState(1);
    const [count, setCount] = useState(0);
    const [despesas, setDespesas] = useState([])
    const [extras, setExtras] = useState([]);
    const [loading, setLoading] = useState(false)
    const [openQrCodeView, setOpenQrCodeView] = useState(false)

    const { enqueueSnackbar } = useCustomSnackbar();

    const getDespesas = async () => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasRepositorio.getDespesas(viagem.id);
            
            setDespesas(response.despesas)
            setExtras(response.extras)
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao buscar despesas.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleChangeTab = useCallback((newValue) => {
        setCurrentTab(newValue);
    }, []);

    useEffect(() => {
        getDespesas()
        setCount(extras.length)
    }, [])

    useEffect(() => {
        getDespesas()
        // setCount(extras.length)
    }, [viagem])

    const handleClickOpenHodometro = (despesa) => {
        setDespesa(despesa)
        setOpenInfoHodometro(true)
    }

    const handleClickOpenComprovante = (despesa) => {
        setDespesa(despesa)
        setOpenComprovante(true)
    }

    const handleClickOpenQrCode = (despesa) => {
        setDespesa(despesa)
        setOpenQrCodeView(true)
    }

    return (
        <>
            <ULoaderVoo isLoading={loading} />
            {despesa != null && (
                <>
                    <DialogComponent
                        title={
                            <>
                                Informações do Hodômetro
                            </>
                        }
                        open={openInfoHodometro}
                        handleClose={() => setOpenInfoHodometro(false)}
                        body={
                            <ViewHodometerExpansesComponent despesa={despesa} isMobile={true} />
                        }
                        action={
                            <></>
                        }
                        width={"sm"}
                    />

                    <DialogViewImage
                        title={
                            <>
                            </>
                        }
                        fullScreen={true}
                        open={openComprovante}
                        handleClose={() => setOpenComprovante(false)}
                        body={
                            <Grid container item xs={12} style={{ display: "flex", alignItems: "center", height: "100%" }}>
                                <img style={{ width: "inherit" }} src={despesa.comprovante}></img>
                            </Grid>
                        }
                        action={
                            <></>
                        }
                        ismap={true}
                        width={"sm"}
                    />

                    <DialogComponent
                        title={
                            <>
                                Informações do QrCode
                            </>
                        }
                        open={openQrCodeView}
                        handleClose={() => setOpenQrCodeView(false)}
                        body={
                            <ViewQrCodeProducts QrCode={despesa?.qrCode} />
                        }
                        action={
                            <></>
                        }
                        fullScreen={true}
                    />
                </>
            )}

            <Card style={{ maxWidth: 500, width: "100%", borderRadius: 10, padding: 0.1, marginTop: "10px" }}>
                <CardContent>
                    {viagem !== null && (
                        <DeskCardPersonExpanses viagem={viagem} despesas={despesas} fullScreen={true} />
                    )}

                    {viagem != null && count != 0 ? (
                        <Grid item xs={12} style={{ justifyContent: "center", alignContent: "center", textAlign: "center", marginBottom: "0px" }}>
                            <ButtonGroup variant="outlined" color="inherit" style={{ justifyContent: "center", borderColor: "#D6D6D6" }}>
                                {[{ value: 1, label: "Despesas" }, { value: 2, label: "Conciliação" }].map((tab) => (
                                    <Button
                                        key={tab.value}
                                        onClick={() => handleChangeTab(tab.value)}
                                        style={{
                                            textTransform: "none", // Mantém a capitalização original do texto
                                            fontWeight: currentTab === tab.value ? 600 : 400, // Destaca o botão ativo
                                            borderColor: "#D6D6D6"
                                        }}
                                    >
                                        <Typography variant="h6" style={{ fontSize: "15px", fontWeight: currentTab === tab.value ? 600 : 400 }}>
                                            {tab.label}
                                        </Typography>
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Grid>
                    ) : (null)}

                    {currentTab == 1 && (
                        <>
                            {despesas?.map((item) => (
                                <>
                                    {item.hodometro ? (
                                        <>
                                            <Divider style={{ marginTop: "5px" }} />
                                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                                <Box sx={{ flex: 1 }}>
                                                    <Typography variant="body1" style={{ fontWeight: "600" }}>Hodometro</Typography>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                                        <Typography variant="body2" color="textSecondary">
                                                            {item.controladoria ? (
                                                                <>
                                                                    <Chip label="Controladoria" color="warning" style={{ marginTop: "-18px", marginLeft: "5px", color: "white" }} icon={<AdminPanelSettingsIcon />}>
                                                                    </Chip>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {item.aprovado ? (
                                                                        <Chip label="Aprovado" color="primary" />
                                                                    ) : (
                                                                        <Chip label="Pendente" color="secondary" />
                                                                    )}
                                                                </>
                                                            )}
                                                            {item.totalauditado != null ? (
                                                                <>
                                                                    <Chip label={"Auditado - " + (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.totalauditado))} color="warning" style={{ marginLeft: "5px", color: "white" }} icon={<CurrencyExchangeOutlined />}>
                                                                    </Chip>
                                                                </>) : (null)}
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant="body2" color="textSecondary"><CreationDate data={item.datacriacao} /></Typography>
                                                    <Typography variant="body2" sx={{ mt: 1 }}>{item.descricao}</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                                <Typography variant="body2"></Typography>
                                                <Typography variant="body2">Total em Km</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                <Typography variant="body2" style={{ marginTop: "auto" }}>{item.despesa_desc}</Typography>
                                                <Typography variant="body2">
                                                    {item.hodometro.kmtotal} Km
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                <Button onClick={() => handleClickOpenHodometro(item)} startIcon={<DirectionsCarIcon />} fullWidth variant="contained" color="primary">Ver Informações</Button>
                                            </Box>
                                        </>
                                    ) : (
                                        <>
                                            <Divider style={{ marginTop: "5px" }} />
                                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                                <Box sx={{ flex: 1 }}>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
                                                        <Typography variant="body1" style={{ fontWeight: "600" }}>Despesa</Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                                        <Typography variant="body2" color="textSecondary">
                                                            {item.controladoria ? (
                                                                <>
                                                                    <Chip label="Controladoria" color="warning" style={{ marginTop: "-18px", marginLeft: "5px", color: "white" }} icon={<AdminPanelSettingsIcon />}>
                                                                    </Chip>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {item.aprovado ? (
                                                                        <Chip label="Aprovado" color="primary" />
                                                                    ) : (
                                                                        <Chip label="Pendente" color="secondary" />
                                                                    )}
                                                                </>
                                                            )}
                                                            {item.totalauditado != null ? (
                                                                <>
                                                                    <Chip label={"Auditado - " + (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.totalauditado))} color="warning" style={{ marginLeft: "5px", color: "white" }} icon={<CurrencyExchangeOutlined />}>
                                                                    </Chip>
                                                                </>) : (null)}
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant="body2" color="textSecondary"><CreationDate data={item.datacriacao} /></Typography>
                                                    <Typography variant="body2" sx={{ mt: 1 }}>{item.descricao}</Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                                <Typography variant="body2">Para</Typography>
                                                <Typography variant="body2">Total</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                <Typography variant="body2" style={{ marginTop: "auto" }}>{item.despesa_desc}</Typography>
                                                <Typography variant="body2">
                                                    {formatarValorComMoeda(item.tipo_moeda, item.total)}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: "center", mt: 1 }}>
                                                {item.qrCode != null && (
                                                    <Button
                                                        startIcon={<QrCode2Outlined />}
                                                        fullWidth
                                                        onClick={() => handleClickOpenQrCode(item)}
                                                    >Utilizado QrCode</Button>
                                                )}
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                <Button startIcon={<ReceiptLongIcon />} onClick={() => handleClickOpenComprovante(item)} fullWidth variant="contained" color="primary">Conferir Comprovante</Button>
                                            </Box>
                                        </>
                                    )}
                                </>
                            ))}
                        </>
                    )}

                    {currentTab == 2 && (
                        <>
                            <ExtraAddComponentM extras={extras} />
                        </>
                    )}
                </CardContent>
            </Card >

        </>
    )
}